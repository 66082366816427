<template>
  <div class="wrap">
      <el-scrollbar style="height:100%">
        <div class="content-wrap mb-20 p-10">
            <div class="d-flex jc-between my-30" style="border-bottom: 1px solid grey;">
                <div>   
                    <div class="title fs-20 mb-15">
                        <strong>{{ articleInfo ? articleInfo.title : ''}}</strong>
                    </div>
                    <div class="fs-15 pb-20" style="color: grey">
                        <span class="mr-30">作者： {{ articleInfo.userId ? articleInfo.userId.username : ''}}</span>
                        <span>发布时间： {{ articleInfo ? articleInfo.created : ''}}</span>
                    </div>
                </div>
                <div class="fs-15 d-flex mb-15" style="align-self: flex-end">
                    <div @click="guanzhu" :class="{active: attention == '已关注'}" class="star-button m-c-p mr-20"><span class="iconfont icon-guanzhu fs-15"></span>&nbsp;{{ attention }}</div>
                    <div @click="shoucang" :class="{active: collect == '已收藏'}" class="star-button m-c-p"> <span class="iconfont icon-shoucang2"></span>&nbsp;{{ collect }}</div>
                </div>
            </div>
            <div style="text-align:center">
                <div class="content fs-15" v-html="articleInfo.content"></div>
            </div>
            
            <div class="give-like p-5 m-c-p" @click="dianzan" @mouseenter="dianzanEnter" @mouseleave="dianzanLeave"> 
                <i class="iconfont icon-dianzan fs-20"></i>
                <span ref="dianzanCount" class="fs-20">{{ dianzanCount }}</span>
                <div :class="[ dianzanActive ]"></div>
            </div>

            <div class="comment-wrap">
                <comment-com :articleInfos="articleInfo"></comment-com>
            </div>
        </div>
        <div class="footer-wrap">
            <footer-com></footer-com>
        </div>
      </el-scrollbar>
  </div>
</template>

<script>
let zanCount = ''
import CommentCom from '../components/CommentCom.vue'
import FooterCom from '../components/Footer.vue'
export default {
    props:['id'],
    data(){
        return {
            articleInfo: {},
            attention: '关注',
            collect: '收藏',
            dianzanActive: '',
            dianzanStatus: '',
            dianzanCount: 0
        }
    },
    methods:{
        async getArticleContent(){
            const model = await this.$axios.get(`rest/Design/${this.id}`)
            this.articleInfo = model.data
        },
        async guanzhu(){
            if(!this.$store.state.user._id){return this.$message({message: '请先登录'})}
            if(this.$store.state.user._id == this.articleInfo.userId._id){
                return this.$message('不能关注自己')
            }
            if(this.attention == '已关注'){
                const result = await this.$axios.get(`/guanzhu/cancel/${this.$store.state.user._id}/${this.articleInfo.userId._id}`)
                result.data && (this.attention = '关注')
                return 
            }
            if(this.attention == '关注'){
                const result = await this.$axios.get(`/guanzhu/add/${this.$store.state.user._id}/${this.articleInfo.userId._id}`)
                result.data && (this.attention = '已关注')
            }
        },
        async shoucang(){
            if(!this.$store.state.user._id){return this.$message({message: '请先登录'})}
            if(this.collect == '收藏'){
                const result = await this.$axios.get(`/collect/Design/add/${this.$store.state.user._id}/${this.articleInfo._id}`)
                result.data && (this.collect = '已收藏')
                return 
            }
            if(this.collect == '已收藏'){
                const result = await this.$axios.get(`/collect/Design/cancel/${this.$store.state.user._id}/${this.articleInfo._id}`)
                result.data && (this.collect = '收藏')
            }
        },
        async dianzan(){
            if(!localStorage.token){
                return this.$message({type: 'warning', message: '请先登录！'})
            }
            if(this.dianzanStatus == 'dianzanActive'){
                this.dianzanActive = ''
                this.dianzanStatus = ''
                await this.$axios.get(`/dianzan/Design/remove/${this.articleInfo._id}/${this.$store.state.user._id}`)
                zanCount --
                this.dianzanCount = zanCount
            }else{
                this.dianzanActive = 'dianzanActive'
                this.dianzanStatus = 'dianzanActive'
                await this.$axios.get(`/dianzan/Design/add/${this.articleInfo._id}/${this.$store.state.user._id}`)
                zanCount ++
                this.dianzanCount =zanCount 
            }
        },
        dianzanEnter(){
            zanCount = this.dianzanCount
            this.dianzanStatus = this.dianzanActive
            this.dianzanActive = 'dianzanHalf'
            if(this.dianzanStatus == 'dianzanActive'){
                this.$refs.dianzanCount.style = 'font-size: 1.4rem'
                this.dianzanCount = '取消点赞'
            }else{
                this.$refs.dianzanCount.style = 'font-size: 1.4rem'
                this.dianzanCount = '点赞'
            }
        },
        dianzanLeave(){
            this.$refs.dianzanCount.style = 'font-size: 2rem'
            this.dianzanCount = zanCount
            this.dianzanActive = this.dianzanStatus
        }
    },
    mounted(){
        this.getArticleContent()
    },
    components:{
        CommentCom,
        FooterCom
    },
    watch:{
        articleInfo(value){
            if(this.$store.state.user.attention){
                this.attention = this.$store.state.user.attention.indexOf(this.articleInfo.userId._id) > -1 ? '已关注' : '关注'
            }
            if(this.$store.state.user.collectArticle){
                this.collect = this.$store.state.user.collectDesign.indexOf(this.articleInfo._id) > -1 ? '已收藏' : '收藏'
            }
            this.dianzanCount = value.like ? value.like.length : 0
            if(value.like){
                if(value.like.indexOf(this.$store.state.user._id) > -1){
                    this.dianzanActive = 'dianzanActive'
                    this.dianzanStatus = 'dianzanActive'
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables';
.wrap{
    height: 100vh;
    width: 100vw;
    margin-top: 5rem;
    .content-wrap{
        width: 140rem;
        margin: 0 auto;
        transform: translate(-5vw, 0);
        min-height: calc(100vh - 25rem);
        .star-button{
            width: 8rem;
            height: 3rem;
            background: map-get($colors, 'purple');
            line-height: 3rem;
            color: white;
            text-align: center;
            border-radius: 0.5rem;
            &.active{
                background: map-get($colors, 'grey');
            }
        }
        .give-like{
            margin: 0 auto;
            margin-top: 5rem;
            margin-bottom: 5rem;
            width: 11rem;
            height: 7rem;
            background: #bbb;
            color: white;
            border-radius: 5rem;
            text-align: center;
            overflow: hidden;
            i{
                display: block;
                font-size: 3rem;
                position: relative;
                z-index: 5;    
            }
            span{
                position: relative;
                z-index: 5;
                transition: all 0.3s ease-in-out;
            }
            div{
                width: 11rem;
                height: 11rem;
                background: map-get($colors, 'purple');
                border-radius: 50%;
                position: relative;
                left: -0.5rem;
                top: 0.8rem;
                transition: all 0.5s ease-out;
            }
            div.dianzanActive{
                transition: all 0.5s ease-out;
                transform: scale(3, 3);
            }
            div.dianzanHalf{
                transition: all 0.5s ease-out;
                transform: scale(1.8, 1.8);
            }
        }
    }
    .content img{
        max-width: 100%;
    }
}
</style>

<style lang="scss">
.content-wrap{
    .content img{
        max-width: 100%;
    }
}
</style>